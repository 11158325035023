import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import StoreProvider from "@/store/StoreProvider";
import { NextApiRequest } from "next";

const AppWrapper = dynamic(() => import("@/components/AppWrapper"));
const DevDashboard = dynamic(() => import("@/components/DevDashboard"));

const DEVELOPER_MODE = process.env.NEXT_PUBLIC_DEVELOPER_MODE || false;

export async function getServerSideProps(request: NextApiRequest) {
  const { sessionId } = request.query;
  let sessionExpired = false;

  let output = {} as any;
  if (sessionId) {
    const res = await fetch(
      `${process.env.URL}/api/session?sessionId=${sessionId}`,
      { method: "GET" },
    );
    if (res && res.status && res.status === 401) {
      sessionExpired = true;
    }

    const data = await res.json();
    const { user, session, workflow } = data || {};

    if (user && session?.uuid && data) {
      output = {
        user,
        session,
        workflow,
      };
    }
  }
  // console.log("app boot ", output?.workflow?.state);
  return { props: { ...output, sessionExpired } };
}

export default function Home({
  user,
  session,
  sessionExpired,
  workflow,
}: {
  user: any;
  session: any;
  sessionExpired: boolean;
  workflow: any;
}) {
  return (
    <StoreProvider>
      <NextSeo
        title="Mastermind Business Academy | Onboarding"
        description="Mastermind Business Academy onboarding"
      />
      <AppWrapper
        user={user}
        session={session}
        sessionExpired={sessionExpired}
        workflow={workflow}
      />
      {DEVELOPER_MODE ? <DevDashboard /> : ""}
    </StoreProvider>
  );
}
