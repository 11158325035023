import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 1,
  totalSteps: 5,
  canGoBack: true,
  loading: false,
  session: {
    uuid: "",
    user: 0,
    expiry: null,
    id: 0,
    workflowId: 0,
  },
  user: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    email: "",
    firstName: "",
    lastName: "",
    pandacontact: null,
    paymentmethodid: null,
    status: "",
  },
  steps: {
    1: {
      done: true,
      data: null,
    },
    2: {
      done: false,
      data: null,
    },
    3: {
      done: false,
      data: null,
    },
    4: {
      done: false,
      data: null,
    },
    5: {
      done: false,
      data: null,
    },
  },
};

const stepObject = (steps: any) => {
  // TODO see if this can be fixed more permanently; might be needed for backward compatibility still (things stored in DB as arrays or strange-keyed objects)
  // steps should be defined as { "1": { order: x, …} }
  // but upon creation, the STATE workflow.steps is an ARRAY not an object

  // console.log("convert steps", steps);

  if (+Object.keys(steps).sort((a, b) => +a - +b)[0] === 1) {
    // steps is an object with non-0 first index,
    // - and is not an array, since key of [0] is always … 0
    return steps;
  }

  // convert [ { order: 1, …}]  to {"1": { order:1, … }}
  try {
    // will throw if steps is an _object_ afterall, and not an array
    // (for example with step #1 called { "0": {xxx}})
    const out = {} as any;
    steps.map((s: any) => {
      out[`${s.order}`] = s;
    });
    // console.log("stepobject returns ARRAY CONVERTED", out);
    return out;
  } catch {
    // rewrite object by member.order,
    const out = {} as any;
    Object.keys(steps).forEach((key) => {
      const entry = steps[key];
      const newKey = `${entry?.order}`; // could fallback to a counter
      out[newKey] = entry;
    });

    // console.log("stepobject returns OBJECT CONVERTED ", out);
    return out;
  }
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setState: (state, action) => {
      const { step, canGoBack, user, steps, session } = action.payload;
      state.step = step;
      // state.totalSteps = totalSteps;
      state.canGoBack = canGoBack;
      state.user = user;

      const newSteps = {} as any;
      // from definition, add done or not
      // TODO should maybe match step ids here instead :thinking:
      const curStepObject = stepObject(JSON.parse(JSON.stringify(state.steps)));
      // console.log("curstep", curStepObject);

      const newStepObject = stepObject(JSON.parse(JSON.stringify(steps)));
      // console.log("newstep", newStepObject);

      Object.keys(state.steps).forEach((k: string) => {
        const orig = curStepObject[k] as any;
        const owrite = newStepObject[k]; // get steps
        // console.log("orig,owr", orig, owrite);
        newSteps[`${k}`] = { ...orig, ...owrite };
      });

      // console.log("wizard sets new steps", newSteps);
      state.steps = newSteps;
      state.totalSteps = Object.keys(newSteps).length;
      state.session = session;
    },
    nextStep: (state) => {
      state.step = state.step + 1;
    },
    previousStep: (state) => {
      state.step = state.step - 1;
    },
    setCanGoBack: (state, action) => {
      state.canGoBack = action.payload;
    },
    setStepState: (state, action) => {
      const { step, data } = action.payload;
      // @ts-ignore
      state.steps[step] = { ...state.steps[step], ...data };
    },
    setSteps: (state, action) => {
      const { steps } = action.payload;
      // console.log("setSteps converted", stepObject(steps));
      state.steps = stepObject(steps);
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  nextStep,
  previousStep,
  setCanGoBack,
  setStepState,
  setSteps,
  setUser,
  setState,
  setSession,
  setLoading, // used to force app to show the loaded when not submitting a form
} = slice.actions;

export default slice.reducer;
