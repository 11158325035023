"use client";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { makeStore } from "./index";

// export default function StoreProvider({ children }: { children: ReactNode }) {
//   const storeRef = useRef();
//   if (!storeRef.current) {
//     // Create the store instance the first time this renders
//     // @ts-ignore
//     storeRef.current = makeStore();
//   }

//   return <Provider store={storeRef.current as any}>{children}</Provider>;
// }

// static store?
const store = makeStore();
const StoreProvider = ({ children }: { children: ReactNode }) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
